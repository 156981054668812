<template>
  <report-layout class="has-background-white" title="Tasks summary">
    <p slot="desc">
      {{
        $report.totalTasks
          ? `In ${$report.timeframe}, ${
              $report.totalTasks === 1
                ? `1 task was`
                : `${$report.totalTasks} tasks were`
            } opened.`
          : `There were no new tasks opened in ${$report.timeframe}.`
      }}
      {{
        $report.totalTasks
          ? `Across all tasks, ${$report.totalTaskPosts} messages were sent and ${$report.totalTaskAttachments} attachment(s) uploaded.`
          : ``
      }}
    </p>

    <template slot="default">
      <div class="column is-4">
        <div class="box dashboard-stat">
          <span class="is-size-6-mobile is-size-5">Total tasks</span>
          <span class="is-size-3-mobile is-size-4 title">
            {{ $report.totalTasks }}
          </span>
        </div>
      </div>

      <div class="column is-4">
        <div class="box dashboard-stat">
          <span class="is-size-6-mobile is-size-5">Total posts</span>
          <span class="is-size-3-mobile is-size-4 title">{{
            $report.totalTaskPosts
          }}</span>
        </div>
      </div>

      <div class="column is-4">
        <div class="box dashboard-stat">
          <span class="is-size-6-mobile is-size-5">Completed tasks</span>
          <span class="is-size-3-mobile is-size-4 title">
            {{ $report.totalTasksCompleted }}
          </span>
        </div>
      </div>

      <div class="column is-12">
        <div class="box">
          <b-table
            hoverable
            :data="$report.tasks"
            :paginated="$report.tasks.length > perPage"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            @click="task => $router.push(`/tasks/${task._id}`)"
          >
            <template slot="empty">
              <no-results
                icon="briefcase"
                title="No tasks"
                message="You didn't create any tasks this month"
              />
            </template>

            <template slot-scope="props">
              <b-table-column label="Reference" width="140">{{
                props.row.reference
              }}</b-table-column>

              <b-table-column label="Type">{{
                props.row.taskType
              }}</b-table-column>

              <b-table-column label="Created" width="100">
                {{ $moment(props.row.dateCreated.toDate()).format("DD/MM/YY") }}
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </template>
  </report-layout>
</template>

<script>
export default {
  name: "ReportTasksRibbon",
  inject: ["$reportProvider"],
  components: {
    "report-layout": () => import("./_layout")
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5
    };
  },
  computed: {
    $report() {
      return this.$reportProvider();
    }
  }
};
</script>
